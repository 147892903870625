/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function() {
        var $grid = $(".grid");

        function triggerIsotope() {
          $grid.isotope({
            itemSelector: ".grid-item", // use a separate class for itemSelector, other than .col-
            percentPosition: true,
            masonry: {
              columnWidth: ".grid-sizer"
            }
          });
        }
        triggerIsotope();

        $grid.imagesLoaded(function() {
          // images have loaded
          console.log("images loaded");
          triggerIsotope();
        });
        //

        // JavaScript to be fired on all pages
        // var $grid = $('.grid');

        // function triggerIsotope() {
        //   // don't proceed if $grid has not been selected
        //   console.log("hallo");

        //   if ( !$grid ) {
        //     return;
        //   }
        //   // init Isotope
        //   $grid.isotope({
        //     itemSelector: '.grid-item',
        //     getSortData: {
        //       time: '[data-num]',
        //       // title: '.entry-title'
        //     },
        //     // sortBy : 'original-order',
        //     // sortBy : 'time',
        //     // sortBy : 'title',
        //     sortAscending: false,
        //     columnWidth: '.grid-sizer',
        //     // columnWidth: '.grid-item',
        //     percentPosition: false,
        //     // masonry:{
        //       // columnWidth: '.grid-sizer'
        //     // },
        //     layoutMode: 'masonry',
        //     // layoutMode: 'moduloColumns',
        //     moduloColumns: {
        //       columnWidth: '.grid-sizer',
        //       gutter: 0
        //     }

        //   });

        //   // $(window).load(function () {
        //   //   console.log("WINDOW LOAD DONE!!!");
        //   //   $grid.isotope('layout');
        //   // });

        // }

        // $grid.imagesLoaded().progress( function() {
        //   console.log("images loaded PROGRESS");
        //   // $grid.isotope('layout');
        //   triggerIsotope();
        //   // $grid.isotope('updateSortData').isotope();
        // });

        // $('.grid').imagesLoaded( function() {
        //   // images have loaded
        //   console.log("images loaded");
        //   triggerIsotope();
        // });

        $(".navbar-toggle").on("click", function() {
          $(this).toggleClass("active");
          $("body").toggleClass("show-menu");
          // $('.offcanvas').css('top': $('.banner').outerHeight() + $('#wpadminbar').outerHeight(), 'height': $('wrap'),outerHeight());
          var wpadminH = $("#wpadminbar").outerHeight(true);
          // var bannerH = $('.banner').outerHeight(true) + $('.nav-logo > div').outerHeight();
          var bannerH =
            $(".banner").outerHeight(true) -
            $(".banner").height() +
            $(".banner > .container .row:last() ").height() +
            3;
          // console.log("banner: " + bannerH);
          var gridH = $(".main").outerHeight(true);

          var menuH = $(".sidebar").outerHeight(true);
          var menuTotalHeight = 0;
          $(".sidebar-container")
            .children()
            .each(function() {
              menuTotalHeight = menuTotalHeight + $(this).outerHeight(true);
            });
          // console.log("gridH: " + menuTotalHeight);
          // console.log("menuH: " + menuH);
          var footerH = 0;
          var menuW = $("body > .container").outerWidth();
          if (menuTotalHeight >= gridH) {
            // footerH = $('footer').outerHeight(true);
            gridH = menuTotalHeight + 35;
          } else {
            // footerH = 40;
          }
          $(".offcanvas").css({
            position: "absolute",
            left: 0
          });
          $(".show-menu .offcanvas, .sidebar-container").css({
            top: -bannerH,
            // 'top': '-30px',
            // 'top': '0',
            // 'left': $('body > .container').offset().left,
            height: gridH + footerH + bannerH + 35,
            // 'height': gridH + 35 ,
            width: menuW,
            maxWidth: "100%"
          });
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    home: {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    studios: {
      init: function() {
        // JavaScript to be fired on the about us page
        //
        // http://terrymun.github.io/paver/demo/index.html
        defaults = {
          // Failure message settings
          failureMessage: "Scroll left/right to pan through panorama.",
          failureMessageInsert: "after",
          gracefulFailure: true,

          // Display settings
          meta: false,
          responsive: true,
          startPosition: 0.5,

          // Minimum overflow before panorama gets converted into a Paver instance
          minimumOverflow: 200,

          // Performance
          grain: 3,
          cursorThrottle: 1000 / 60,
          gyroscopeThrottle: 1000 / 60,
          resizeThrottle: 500,

          // For mousemove event
          mouseSmoothingFunction: "linear",

          // For deviceOrientationEvent
          tilt: true,
          tiltSensitivity: 1,
          tiltScrollerPersistence: 500,
          tiltSmoothingFunction: "gaussian",
          tiltThresholdPortrait: 12,
          tiltThresholdLandscape: 24
        };
        $(function() {
          $("div.panorama").paver(defaults);
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, "_").split(/\s+/), function(
        i,
        classnm
      ) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, "finalize");
      });

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
